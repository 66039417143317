// import router from '@/router';
import axios from "axios";
import { authHeaders } from "@/store/config";

const state = {
  matters: []
};

const getters = {

};

const actions = {
  createMatter(context, data) {
    
    return new Promise((resolve, reject) => {
      axios
        .post("/matter", data, {
          headers: {
            ...authHeaders(localStorage.getItem('access_token'))
          },
        })
        .then((response) => {
          context.commit('add_matter_obj', response.data.data)
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createBulkMatter(context, data) {
    
    return new Promise((resolve, reject) => {
      axios
        .post("/matter/bulk-upload", data, {
          headers: {
            ...authHeaders(localStorage.getItem('access_token'))
          },
        })
        .then((response) => {
          context.commit('add_matter_obj', response.data.data)
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteMatter(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/matter/${data.id}`, {
          headers: {
            ...authHeaders(localStorage.getItem('access_token'))
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateMatter(context, data) {
    
    return new Promise((resolve, reject) => {
      axios
        .put(`/matter/${data.id}`, data.matter, {
          headers: {
            ...authHeaders(localStorage.getItem('access_token'))
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAllMatter(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("/matter/all", {
          headers: {
            ...authHeaders(localStorage.getItem('access_token'))
          },
        })
        .then((response) => {
          if(response){

            context.commit('set_matters', response.data.data)
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

};

const mutations = {
  add_matter_obj(state, data) {
    state.matters.push(data)
  },
  set_matters(state, data) {
      state.matters = data
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
